<template>
  <div>
    <a-modal v-model="visible" title="物料选择" width="750px" :footer="null" @cancel="onCloseModel">
      <a-steps v-model="current">
        <a-step title="选择类型" status="process"></a-step>
        <a-step title="监控"></a-step>
        <a-step title="收银机"></a-step>
        <a-step title="门"></a-step>
        <a-step title="门框"></a-step>
        <a-step title="进门方式门"></a-step>
      </a-steps>
      <div style="margin-top: 12px;" v-if="current===0">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        </a-table>
        <div class="steps-action">
          <a-button v-if="current < 5" type="primary" @click="next">
            下一步
          </a-button>
        </div>
      </div>
      <div style="margin-top: 12px;" v-if="current===1">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        </a-table>
        <div class="steps-action">
          <a-button @click="prev">
            上一步
          </a-button>
          <a-button type="primary" style="margin-left: 8px" @click="next">
            下一步
          </a-button>
        </div>
      </div>
      <div style="margin-top: 12px;" v-if="current===2">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        </a-table>
        <div class="steps-action">
          <a-button @click="prev">
            上一步
          </a-button>
          <a-button type="primary" style="margin-left: 8px" @click="next">
            下一步
          </a-button>
        </div>
      </div>
      <div style="margin-top: 12px;" v-if="current===3">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        </a-table>
        <div class="steps-action">
          <a-button @click="prev">
            上一步
          </a-button>
          <a-button type="primary" style="margin-left: 8px" @click="next">
            下一步
          </a-button>
        </div>
      </div>
      <div style="margin-top: 12px;" v-if="current===4">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        </a-table>
        <div class="steps-action">
          <a-button @click="prev">
            上一步
          </a-button>
          <a-button type="primary" style="margin-left: 8px" @click="next">
            下一步
          </a-button>
        </div>
      </div>
      <div style="margin-top: 12px;" v-if="current===5">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        </a-table>
        <div class="steps-action">
          <a-button @click="prev">
            上一步
          </a-button>
          <a-button type="primary" style="margin-left: 8px" @click="next">
            完成
          </a-button>
        </div>
      </div>
    </a-modal>


    <!-- <a-modal v-model="visible" title="物料选择" width="750px" :footer="null" @cancel="onCloseModel">
      <a-row :gutter="16">
        <a-col :span="12" style="width: 256px;">
          <a-input v-model="searchForm.search" placeholder="产品名称, 编码, 条码" allowClear @pressEnter="search" />
        </a-col>
        <a-space>
          <a-button type="primary" @click="confirmSelected">确认</a-button>
        </a-space>
        <a-col :span="12" style="width: 256px;">
            <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>

      <div style="margin-top: 12px;">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        </a-table>
      </div>

    </a-modal> -->
  </div>
</template>

<script>
  import { salePackageList, posList } from '@/api/option';
import { runInThisContext } from 'vm';
  import { columns } from './columns';

  export default {
    props: ['visible', 'warehouse'],
    model: { prop: 'visible', event: 'cancel' },
    data() {
      return {
        columns,
        current: 0,
        pagination: {},
        searchForm: { search: '' },
        params: {level: 1},
        level: 1,
        loading: false,
        items: [],
        selectedRowKeys:[],
        selectedRows: [],
        selectedGoods:[],
        is_leaf: false
      }
    },
    methods: {
      next() {
        console.log(this.current);
        switch(this.current ){
          case 0:
            
            if(this.is_leaf){
              this.current--;
              this.level--;
              this.confirmSelected();
            };
            break;
          case 3:
            if(this.is_leaf){
              this.current--;
              this.level--;
              this.confirmSelected();
            };
            break;
          case 5:
            this.current--;
            this.level--;
            this.confirmSelected();
            break;
        }
        this.current++;
        this.level++;
        this.list()
      },
      prev() {
        this.current--;
        this.level--;
        this.list()
      },
      onCloseModel() {
        this.$emit('cancel', false);
      },
      onChangeTable(pagination, filters, sorter) {
        this.pagination = pagination;
        this.searchForm.page = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.list();
      },
      list() {
        this.loading = true;
        let params = {level: this.level};
        if(this.level === 3){
          posList().then(data => {
          this.items = data;
        }).finally(() => {
          this.loading = false;
        });
        }else{
          salePackageList(params).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
        }
      },
      select(item) {
        this.$emit('select', item);
        this.onCloseModel();
      },
      confirmSelected() {
        this.$emit('select', this.selectedGoods);
        this.onCloseModel();
      },
      resetModel() {
        this.pagination = { current: 1, total: 0, pageSize: 15, showTotal: total => `共 ${total} 条` };
        this.searchForm = { page: 1, warehouse: this.warehouse, is_active: true };
        this.items = [];
      },
      onSelectChange(selectedRowKeys, selectedRows) {
        let self = this;
        this.selectedRowKeys = selectedRowKeys;
        if(this.level === 0){
          this.selectedGoods = [];
        }
        selectedRows.forEach(function(item){
          self.is_leaf = item.is_leaf
          if(self.selectedRows.indexOf(item) < 0){
            self.selectedRows.push(item);
          }
        });
        self.selectedRows.forEach(function(item){
          if(selectedRowKeys.indexOf(item.id) < 0){
            self.selectedRows.splice(self.selectedRows.indexOf(item), 1);
          }
        })
        self.selectedGoods = [];
        this.selectedRows.forEach(function(item){
          item.products.forEach(function(goods){
            self.selectedGoods.unshift(
              {
                id: goods.id,
                goods: goods.id,
                goods_number: goods.number,
                goods_name: goods.name,
                goods_spec: goods.spec,
                unit_name: goods.unit_name,
                retail_price: goods.retail_price
              }
            )
          })
        })
      },
    },
    watch: {
      visible(status) {
        if (status) {
          this.resetModel();
          this.list();
        }
      },
    },
  }
</script>